<script setup>
import { computed } from "vue"

const props = defineProps({
  appendIcon: {
    type: String,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
  },
  errorMessages: {
    type: [Array, String],
    default: () => {
      return []
    },
  },
  hideDetails: {
    type: [Boolean, String],
    default: false,
  },
  hint: {
    type: String,
  },
  label: {
    type: String,
  },
  max: {
    type: [Number, String],
  },
  min: {
    type: [Number, String],
  },
  persistentHint: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  singleLine: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  modelValue: {},
})

const emit = defineEmits([
  "blur",
  "change",
  "click",
  "input",
  "keyup",
  "update:modelValue",
])

const labelProcessed = computed(() => {
  if (props.required) {
    return props.label + " *"
  }
  return props.label
})

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
</script>

<template>
  <v-text-field
    v-model="localValue"
    :append-icon="appendIcon"
    :autofocus="autofocus"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :hint="hint"
    :label="labelProcessed"
    :max="max"
    :min="min"
    variant="outlined"
    :persistent-hint="persistentHint"
    :placeholder="placeholder"
    :required="required"
    :single-line="singleLine"
    :type="type"
    @blur="emit('blur', $event)"
    @change="emit('change', $event)"
    @click="emit('click', $event)"
    @update:model-value="emit('input', $event)"
    @keyup="emit('keyup', $event)"
  >
    <template #prepend-inner>
      <slot name="prepend-inner"/>
    </template>
    <template #append-inner>
      <slot name="append-inner"/>
    </template>
  </v-text-field>
</template>
